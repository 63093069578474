import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";

import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";

const StyledPrivacy = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  #contact-about {
    margin-left: -40px;
    margin-right: -40px;
    h2:first-child {
      display: none;
    }
  }
  em {
    text-decoration: underline;
  }
  a.link-primary {
    color: ${colors.secondaryAlt};
  }
  .content-area {
    margin: 0.75em auto;
    max-width: 800px;
  }
  table {
    display: table;
    border-collapse: collapse;
    border-spacing: 2px;
    font-size: 12px;
    border-color: ${colors.black};
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: ${colors.black};
  }
  tr {
    border: 1px solid ${colors.black};
  }
  td {
    border-right: 1px solid ${colors.black};
    border-bottom: 1px solid ${colors.black};
    padding: 5px;
  }
  tbody {
    color: ${colors.black};
  }
`;

const StyledBreak = styled.div`
  width: 80%;
  margin: 4em auto 2em auto;
`;

class PrivacyPage extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== "undefined",
      viewPort: window.innerWidth >= 768 ? "desktop" : "mobile"
    });
    if (this.state.isBrowser) {
      window.addEventListener("resize", this.setViewport);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const privacy_page = this.props.data.wordpressPage;
    const isBrowser = typeof window !== "undefined";

    if (this.state.isPreviewMode) {
      privacy_page.title = this.state.previewPayload.title.rendered;
      privacy_page.content = this.state.previewPayload.content.rendered;
      privacy_page.acf = this.state.previewPayload.acf;
    }
    return (
      <StyledPrivacy>
        {!this.props.isPreviewMode && (
          <SEO
            isBlogPost={false}
            postData={this.props.data.wordpressPage}
            postImage={DefaultSharingImage}
          />
        )}
        <Hero
          headline={privacy_page.title}
          sub={privacy_page.acf.hero_sub_headline}
        />
        <StyledBreak />
        <div className="page-container">
          <p>
            <div dangerouslySetInnerHTML={{ __html: privacy_page.content }} />
          </p>
        </div>
      </StyledPrivacy>
    );
  }
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "privacy" }) {
      content
      slug
      title
      type
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default PrivacyPage;
